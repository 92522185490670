@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

body {
  margin: 0;
  padding: 0;
  background-color: #212121;
  text-rendering: optimizeLegibility;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.shadow {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

a,
a:visited {
  color: #7b4dff;
  cursor: pointer;
  text-decoration: underline;
}

.nounder {
  text-decoration: none;
}

pre {
  margin-top: 0;
}

small {
  font-size: .8em;
}
