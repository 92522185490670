@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
.deviceHeaderWrapper{display:-webkit-flex;display:flex;background-color:#343434;color:#929292;padding:4px 4px 4px 14px;font-family:"Lato","Helvetica","Arial",sans-serif;font-size:14px;font-weight:bold}.deviceHeaderWrapper .title{-webkit-flex:1 1;flex:1 1;text-transform:uppercase;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.deviceHeaderWrapper .title .place{margin-right:10px}.deviceHeaderWrapper .headerControlsWrapper{display:-webkit-flex;display:flex;-webkit-justify-content:space-evenly;justify-content:space-evenly;-webkit-align-items:center;align-items:center}.deviceHeaderWrapper .headerControlsWrapper .dryWetContainer{display:-webkit-flex;display:flex;margin-right:10px}.deviceHeaderWrapper .headerControlsWrapper .dryWetContainer>*:not(:last-child){margin-right:10px}.deviceHeaderWrapper .headerControlsWrapper .midSideContainer{margin-right:10px}.deviceHeaderWrapper .headerControlsWrapper .midSideContainer>*:not(:last-child){margin-right:4px}.headerButtonWithLabel{font-size:11px}.headerButtonWithLabel .headerButtonTitle{margin-right:5px}.headerButton{border-width:1px;border-style:solid;border-color:#929292;box-sizing:border-box;border-radius:5px;margin:0px 5px;padding:5px 8px;background-color:transparent;font-weight:bold;color:#929292;font-size:11px;cursor:pointer;touch-action:none}.headerButton .invisible{opacity:0}.headerButton.minWidth{min-width:46px}.headerButton.bypassActive{background-color:#e74c3c;border-color:#e74c3c;color:#fff}.headerButton.bypassActive:hover{background-color:#fa7a6d;border-color:#fa7a6d}.headerButton:hover{background-color:#606060;border-color:#f7f5f9;color:#f7f5f9}.headerButton:focus{outline:none}.headerButton.active{background-color:#cbcaca;border-color:#cbcaca;color:#343434}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Digitopik2';
    font-style: normal;
    font-weight: normal;
    src: local('Digitopik2'), url(/static/media/Digitopik2-Regular.f7b684d9.woff) format('woff');
}

body {
  margin: 0;
  padding: 0;
  background-color: #212121;
  text-rendering: optimizeLegibility;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.shadow {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

a,
a:visited {
  color: #7b4dff;
  cursor: pointer;
  text-decoration: underline;
}

.nounder {
  text-decoration: none;
}

pre {
  margin-top: 0;
}

small {
  font-size: .8em;
}

