$background: #343434;
$grey: #929292;
$grey-3: #444444;
$grey-3: #606060;
$grey-4: #cbcaca;
$grey-5: #f7f5f9;
$red: #e74c3c;
$red-2: #fa7a6d;

.deviceHeaderWrapper {
    display: flex;
    background-color: $background;
    color: $grey;
    padding: 4px 4px 4px 14px;
    font-family: 'Lato', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: bold;

    .title {
        flex: 1;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .place {
            margin-right: 10px;
        }
    }

    .headerControlsWrapper {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .dryWetContainer {
            display: flex;
            margin-right: 10px;
            > *:not(:last-child) {
                margin-right: 10px;
            }
        }

        .midSideContainer {
            margin-right: 10px;
            > *:not(:last-child) {
                margin-right: 4px;
            }
        }
    }
}

.headerButtonWithLabel {
    font-size: 11px;
    .headerButtonTitle {
        margin-right: 5px;
    }
}

.headerButton {
    border-width: 1px;
    border-style: solid;
    border-color: $grey;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0px 5px;
    padding: 5px 8px;
    background-color: transparent;
    font-weight: bold;
    color: $grey;
    font-size: 11px;
    cursor: pointer;
    touch-action: none;

    .invisible {
        opacity: 0;
    }

    &.minWidth {
        min-width: 46px;
    }

    &.bypassActive {
        background-color: $red;
        border-color: $red;
        color: #fff;

        &:hover {
            background-color: $red-2;
            border-color: $red-2;
        }
    }

    &:hover {
        background-color: $grey-3;
        border-color: $grey-5;
        color: $grey-5;
    }
    &:focus {
        outline: none;
    }
    &.active {
        background-color: $grey-4;
        border-color: $grey-4;
        color: $background;
    }
}
